.App {
  text-align: center;
}

.App-logo {
  max-width: 100%;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Quote {
  color: #666666;
  font-style: italic;
  font-size: larger;
}

.Spotify-link {
  color: #61dafb;
  text-decoration: none;
}
